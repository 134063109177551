
/* Css para el body */
/* este property incluye el padding y el borde dentro del total */
/* de declaraciones de height y width*/
* {
  box-sizing: border-box
}

/* Css para el layout */
.body-bg {
  background-size:cover;
  background-repeat:repeat-y;
  width:100%;
  height:100%;
  padding-top: 0px;
  margin-top: 110px;

  /* background-color:  green; */
  /* background-color:  #8bc34a; */
  /* background-color: whitesmoke; */
  /* background-image: url('./imagenes/19-2.jpeg');  */
  background-position:top;
}

.titulo-pagina   {
  /* important hace overwrite del margin-top que esta agregando el h2 */
  margin-top: 30px !important;  
  padding-top: 100 px;
  color: black;
  font-size: 40px;
  font-style: bold; 
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* background-color: red; */
}

/* estilos viejos del index, borrar sino se ocupan */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


/* Css para BarraNav */
.tit-ciqpa {
  font-size: 20px
}
/* .barra-nav {
  padding-bottom: 0px; 
  margin-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
} */

/* css para marcar item activo en el navbar */
a.active {
  background-color: darkgreen
  /* text-decoration: underline;
  text-decoration-color: lightgreen; */
}

/* Css para estilo general de cards */
/* Lo uso en cards de cotizacion y servicios */
.estilo-general-card {
  max-width: 80%;
}

/* Css para mensaje de error en campos de forms */
.msg-error-campo {
  color: red;
  /* background-color: blue; */
  font-size: 12px;
  margin-top: 0px;
  padding: 0px;
}

.estilo-footer {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.estilo-texto-fotter {
  padding: 10px
}

.curso-item-card-text-sz {
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 5px;
  padding: 0px;
  width: 320px;
  height: 120px;
}

.curso-item-card-boton {
  margin-left: 40px;
}

.preguntas-panel1-card {
  width: 200px;
}

.preguntas-panel2-card {
  width: 200px;
}

.preguntas-panel1-titulo  {
  /* important hace overwrite del margin-top que esta agregando el h2 */
  width: 100% !important;
  margin-top: 10px !important; 
  padding-top: 100 px;
  color: black;
  font-size: 20px;
  font-style: bold; 
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* background-color: red; */
}

.preguntas-panel1-boton {
  width: 50% !important
}

/* 
Estilos para el Card del ComponentePreguntasPanel2
Este es el panel derecho donde se despliega la pregunta seleccionada
*/

.preguntas-titulo {
  margin: 20px;
  color: black;
  font-size: 25px;
  /* font-style: bold;  */
  /* text-align: center; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}